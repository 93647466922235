<template>
    <el-row :gutter="20" class="px-4">
        <h5>操作文档</h5>
        <ul>
            <li v-for="x in pdfs" :key="x.name" :icon="x.icon" :command="x.link">
                {{ x.name }} <br />....
                <a :href="x.link" target="_blank"><i class="el-icon-search"></i> 查看</a>
            </li>
        </ul>

        <el-divider></el-divider>
        <h5>操作视频</h5>
        <ul>
            <li v-for="x in mp4s" :key="x.name" :icon="x.icon" :command="x.link">
                <span >{{ x.name }}</span> <br />....
                <a :href="x.link" target="_blank"><i class="el-icon-video-play"></i> 播放</a>
            </li>
        </ul>

        <el-divider></el-divider>
        <h5>相关连接</h5>
        <ul>
            <li v-for="x in links" :key="x.name" :icon="x.icon" :command="x.link">
                <span >{{ x.name }}</span><br />....
                <a :href="x.link" target="_blank"><i class="el-icon-link"></i> 跳转</a>
            </li>
        </ul>
    </el-row>
</template>

<script>
import ManualList from "../mixins/manual-list-mixin";

export default {
    name: "manual-list",
    data() {
        return {}
    },
    mixins: [ManualList],
}
</script>

