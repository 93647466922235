<template>
    <el-row :gutter="20">
        <el-col :md="24" :sm="24" class="mb-3">
            <el-card class="profile" shadow="never">
                <div slot="header" class="">
                    <b class="user-name">{{user.name}}</b>
                </div>

                <div class="mt-3">
                    <el-form :model="profile" ref="profile" label-width="100px" class="">
                        <el-form-item prop="user_id" label="登录名">
                            <el-input v-model="profile.user_id" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item
                                prop="name"
                                label="姓名"
                                :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' },]">
                            <el-input v-model="profile.name" placeholder="您需填写姓名才可以继续使用其他功能。"></el-input>
                        </el-form-item>
                        <el-form-item prop="email"
                                      label="邮箱"
                                      :disabled="user.email"
                                      >
                            <el-input v-model="profile.email" placeholder="您需要输入邮箱才可以继续使用其他功能。"></el-input>
                        </el-form-item>
                        <el-form-item prop="mobile"
                                      label="手机">
                            <el-input v-model="profile.mobile" placeholder="选填"></el-input>
                        </el-form-item>
                        <el-form-item
                                :disabled="true"
                                prop="department"
                                label="单位">
                            <el-input v-model="profile.department"></el-input>
                        </el-form-item>
                        <el-form-item
                                prop="old_password"
                                label="旧密码" autocomplete = "off" >
                            <el-input v-model="profile.old_password" placeholder="如需改密码，请在此输入旧密码，没有旧密码留空白"></el-input>
                        </el-form-item>
                        <el-form-item
                                :disabled="true"
                                prop="password" autocomplete = "off"
                                label="密码">
                            <el-input show-password v-model="profile.password" placeholder="如需改密码，请在此输入新密码"></el-input>
                        </el-form-item>
                        <el-form-item v-if="!user.disabled" class="mt-5">
                            <el-button type="primary" @click="submitForm" icon="el-icon-s-promotion">提交</el-button>
                            <el-button @click="resetForm" icon="el-icon-brush">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
        </el-col>
    </el-row>

</template>

<script>

    import {mapState, mapMutations} from "vuex";

    export default {
        name: "profile",
        data() {
            return {
                profile: {
                    name: '',
                    user_id: '',
                    department: '',
                    mobile: '',
                    email: '',
                    old_password:'',
                    password:'',
                },
                sending: false
            }
        },
        computed: {
            ...mapState(['user', 'process','preset']),
        },
        mounted() {
            this.resetForm();
        },
        methods: {
            ...mapMutations('user', ['setUserInfo']),
            resetForm() {
                this.profile.name = this.user.name;
                this.profile.user_id = this.user.user_id;
                this.profile.department = this.user.department;
                this.profile.mobile = this.user.mobile;
                this.profile.email = this.user.email;
                this.profile.password = '';
                this.profile.old_password = '';
            },
            submitForm() {
                const vm = this;
                this.$refs['profile'].validate((valid) => {
                    if (valid) {
                        this.$api('User.Profile', this.profile, true).then((response) => {
                                vm.setUserInfo(response.data.data);
                                vm.$notify({
                                    title: '成功',
                                    message: '档案更新完成!',
                                    type: 'success'
                                })
                                this.$router.push('/');
                            }
                        );
                    } else {
                        this.$notify({
                            title: '错误',
                            message: '请按照指示填写正确的信息',
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../global";
    .profile {
        .image {
            width: 36px;
            height: 36px;
        }
        .user-name {
            font-size:18px;
            color: $--color-primary;
            margin-top: 5px;
            padding-right:10px;
        }
    }


</style>
